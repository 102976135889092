<template>
  <modal
    id="modalAdicionarPrecos"
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="item.nomeRelatorio"
    :campos="$refs"
    :ocultarRodape="true"
    tamanho="md"
  >
    <input-select-search
      ref="contrato"
      v-model="data"
      :label="$t(`ACADEMICO.CONTRATOS`)"
      :options="opcoes.contratosAluno"
      required
    />
    <div class="d-flex justify-content-between">
      <b-button variant="secondary" @click="fecharModal()">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
      <b-button variant="primary" @click="imprimirRelatorios">
        {{ $t('GERAL.IMPRIMIR') }}
      </b-button>
    </div>
  </modal>
</template>

<script>
// AUX & HELPERS
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';

// SERVICES
import ContratosService from '@/common/services/contratos/contratos.service';

import PainelCoordenacaoService from '@/common/services/painel-coordenacao/painel-coordenacao.service.js';

// COMPONENTS
import Modal from '@/components/modal/Modal.vue';
import { InputSelectSearch } from '@/components/inputs';

export default {
  name: 'ModalImprimirRelatorios',
  components: {
    Modal,
    InputSelectSearch,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: Object },
  },
  data() {
    return {
      opcoes: {
        contratosAluno: [],
      },
      data: '',
    };
  },
  watch: {
    'item.id': 'getContratos',
  },
  methods: {
    // OBTER DADOS
    getContratos() {
      if (!this.item.id) return;
      this.$store.dispatch(START_LOADING);
      ContratosService.buscarContratoVigentePorMatriculaId(this.item.id)
        .then(({ data }) => {
          this.opcoes.contratosAluno = data.itens.map((contrato) => ({
            ...contrato,
            value: { ...contrato },
            text: `Codigo: ${contrato.codigoContrato} - Grupo Turma: ${contrato.grupoTurmaDescricao} - Ano letivo: ${contrato.anoLetivoDescricao}`,
          }));
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    imprimirRelatorios() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      PainelCoordenacaoService.imprimir(
        {
          ...this.item,
          ...this.data.value,
          matriculaId: this.item.id,
        },
        this.item.documentoCodigo
      )
        .then(({ data }) => {
          data.forEach((element) => {
            if (element.documento) {
              //converte os bytes em array:
              let bytes = pdf.base64ToArrayBuffer(element.documento);

              // converte o array em blob
              let blob = new Blob([bytes], { type: 'application/pdf' });

              // faz download do PDF:
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = element.nomeArquivo;
              link.click();
            }
          });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    fecharModal() {
      this.$emit('fechar');
    },

    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
