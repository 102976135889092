<template>
  <div>
    <div>
      <topbar
        :titulo="$t('ACADEMICO_MATRICULAS.MATRICULAS')"
        :subtitulo="$t('ACADEMICO_MATRICULAS.MATRICULAS_DESCRICAO_PAGE')"
        :breadcrumbs="breadcrumbs"
      >
        <template #header>
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-2 ml-md-4"
          >
            <b-button
              variant="info"
              class="text-nowrap"
              @click="irPara('nova-matricula')"
            >
              {{ $t('ACADEMICO_MATRICULAS.NOVA_MATRICULA') }}
            </b-button>
            <b-button
              variant="info"
              class="text-nowrap"
              @click="irPara('nova-pessoa', '', 'matriculas')"
            >
              {{ $t('ACADEMICO_MATRICULAS.NOVA_PESSOA') }}
            </b-button>
          </div>
        </template>
      </topbar>

      <filtro-aluno-matricula
        class="mt-4"
        @filtrar="getFiltro"
        @limpar-filtro="getMatriculasPorFiltroPaginado"
      />

      <tabela-matriculas
        :items="items"
        class="tamanho"
        @editar="irPara"
        @visualizar="irPara"
        @atualizar-tabela="getMatriculasPorFiltroPaginado"
      />
    </div>

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getMatriculasPorFiltroPaginado"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service';

// Components:
import TabelaMatriculas from '@/views/academico/matriculas/components/Tabela';
import FiltroAlunoMatricula from '@/views/academico/matriculas/components/Filtro';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  components: {
    FiltroAlunoMatricula,
    TabelaMatriculas,
    Paginacao,
    Topbar,
  },
  data() {
    return {
      items: [],
      filtrandoPor: '',
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.MATRICULAS') },
      ],
    };
  },
  mounted() {
    this.getMatriculasPorFiltroPaginado();
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getMatriculasPorFiltroPaginado(filtro) {
      this.filtrandoPor = filtro === '' ? filtro : this.filtrandoPor;
      this.$store.dispatch(START_LOADING);
      MatriculaService.obterMatriculasPorFiltroPaginado(
        this.filtrandoPor,
        this.paginacao
      )
        .then(({ data }) => {
          this.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DO FILTRO:
    getFiltro(buscarPor) {
      this.filtrandoPor = buscarPor;
      this.getMatriculasPorFiltroPaginado();
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id, linkRetornar) {
      rotas.irPara(rota, id, linkRetornar);
    },
  },
};
</script>

<style>
.gap-2 {
  gap: 10px;
}
</style>
