<template>
  <div>
    <b-table
      :empty-text="$t('ACADEMICO_MATRICULAS.NENHUM_MATRICULA_REGISTRADA')"
      head-variant="light"
      :items="items"
      :fields="fields"
      show-empty
      stacked="md"
      hover
    >
      <template #cell(acoes)="item">
        <div class="flex" v-if="mostrarAcoes">
          <b-dropdown variant="outline-default" class="tamanho">
            <b-dropdown-item
              @click="editar(item.item.id)"
              v-if="podeEditar && item.item.status == 'Ativo'"
            >
              {{ $t('GERAL.EDITAR') }}
            </b-dropdown-item>
            <b-dropdown-item
              @click="ativarMatricula(item.item)"
              v-if="podeExcluir && item.item.status == 'Inativo'"
            >
              {{ $t('GERAL.ATIVAR') }}
            </b-dropdown-item>
            <b-dropdown-item
              @click="inativarMatricula(item.item)"
              v-if="podeExcluir && item.item.status == 'Ativo'"
            >
              {{ $t('GERAL.INATIVAR') }}
            </b-dropdown-item>
            <b-dropdown-item
              @click="visualizar(item.item.id)"
              v-if="item.item.status == 'Ativo'"
            >
              {{ $t('GERAL.VISUALIZAR') }}
            </b-dropdown-item>

            <!-- Relatórios -->
            <b-dropdown-item
              @click="
                abrirModalPdfs({
                  ...item.item,
                  documentoCodigo: 3,
                  nomeRelatorio: $t(
                    'ACADEMICO_MATRICULAS.IMPRIMIR_ATESTADO_FREQUENCIA'
                  ),
                })
              "
            >
              {{ $t('ACADEMICO_MATRICULAS.IMPRIMIR_ATESTADO_FREQUENCIA') }}
            </b-dropdown-item>
            <b-dropdown-item
              @click="abrirModalImprimirBoletimENotasParciais(item.item)"
            >
              {{ $t('ACADEMICO_MATRICULAS.IMPRIMIR_BOLETIM_NOTAS_PARCIAIS') }}
            </b-dropdown-item>
            <b-dropdown-item @click="abrirModalImprimirHistorico(item.item)">
              {{ $t('ACADEMICO_MATRICULAS.IMPRIMIR_HISTORICO_ESCOLAR') }}
            </b-dropdown-item>
            <b-dropdown-item @click="abrirModalImprimirCertificado(item.item)">
              {{ $t('ACADEMICO_MATRICULAS.IMPRIMIR_CERTIFICADO') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <modal-imprimir-relatorios
      :exibir="modais.exibirBaixarPdfs"
      :item="modais.baixarPdf"
      @fechar="fecharModalPfs"
    />
    <modal-imprimir-historico
      :exibir="modais.exibirImprimirHistorico"
      :item="modais.historicoDadosAluno"
      @fechar="fecharModalImprimirHistorico"
    />
    <modal-imprimir-certificado
      :exibir="modais.exibirImprimirCertificado"
      :item="modais.certificadoDadosAluno"
      @fechar="fecharModalImprimirCertificado"
    />
    <modal-imprimir-boletim
      :exibir="modais.exibirImprimirBoletim"
      :item="modais.dadosBoletim"
      @fechar="fecharModalImprimirBoletimENotasParciais"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import Permissoes from '@/components/mixins/permissoes';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service';

//components
import ModalImprimirRelatorios from '@/views/academico/matriculas/ModalImprimirRelatorios.vue';
import ModalImprimirCertificado from '@/views/academico/matriculas/components/modais/ModalImprimirCertificado.vue';
import ModalImprimirHistorico from '@/views/academico/matriculas/components/modais/ModalImprimirHistorico.vue';
import ModalImprimirBoletim from '@/views/academico/matriculas/components/modais/ModalImprimirBoletim.vue';

export default {
  mixins: [Permissoes],
  components: {
    ModalImprimirRelatorios,
    ModalImprimirHistorico,
    ModalImprimirBoletim,
    ModalImprimirCertificado,
  },
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'codigoMatricula',
          label: this.$t('ACADEMICO_MATRICULAS.CODIGO_MATRICULA'),
        },
        {
          key: 'nome',
          label: this.$t('ACADEMICO_MATRICULAS.ALUNO'),
        },
        {
          key: 'status',
          label: this.$t('ACADEMICO_MATRICULAS.STATUS'),
        },
        {
          key: 'observacao',
          label: this.$t('ACADEMICO_MATRICULAS.OBSERVACAO'),
          formatter: (value) => {
            return value ? `${value?.substring(0, 25)}...` : '-';
          },
        },
        {
          key: 'acoes',
          label: this.$t('ACADEMICO_MATRICULAS.ACOES'),
        },
      ],
      modais: {
        exibirImprimirBoletim: false,
        exibirBaixarPdfs: false,
        baixarPdf: {},
        exibirImprimirHistorico: false,
        historicoDadosAluno: {},
        exibirImprimirCertificado: false,
        certificadoDadosAluno: {},
        dadosBoletim: {},
      },
    };
  },
  computed: {
    mostrarAcoes() {
      return this.podeEditar || this.podeExcluir;
    },
  },
  methods: {
    ativarMatricula(item) {
      mensagem
        .confirmacao(
          null,
          this.$t('ACADEMICO_MATRICULAS.CONFIRMAR_ATIVACAO').formatUnicorn({
            pessoa: item.nome,
          })
        )
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            MatriculaService.ativar(item.id)
              .then(() => {
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('ACADEMICO_MATRICULAS.MATRICULA_ATIVADA')
                );
                this.$emit('atualizar-tabela');
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
    inativarMatricula(item) {
      mensagem
        .confirmacao(
          null,
          this.$t('ACADEMICO_MATRICULAS.CONFIRMAR_INATIVACAO').formatUnicorn({
            pessoa: item.nome,
          })
        )
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            MatriculaService.inativar(item.id)
              .then(() => {
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('ACADEMICO_MATRICULAS.MATRICULA_INATIVADA')
                );
                this.$emit('atualizar-tabela');
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },

    editar(matriculaId) {
      rotas.irPara('editar-matricula', matriculaId);
    },

    visualizar(matriculaId) {
      rotas.irPara('visualizar-matricula', matriculaId);
    },

    // modais
    //RELATÓRIOS & MODAIS
    abrirModalPdfs(item) {
      this.modais.exibirBaixarPdfs = true;
      this.modais.baixarPdf = item;
    },

    fecharModalPfs() {
      this.modais.exibirBaixarPdfs = false;
      this.modais.baixarPdf = {};
    },

    abrirModalImprimirHistorico(item) {
      this.modais.exibirImprimirHistorico = true;
      this.modais.historicoDadosAluno = item;
    },

    abrirModalImprimirCertificado(item) {
      this.modais.exibirImprimirCertificado = true;
      this.modais.certificadoDadosAluno = item;
    },

    fecharModalImprimirHistorico() {
      this.modais.exibirImprimirCertificado = false;
      this.modais.certificadoDadosAluno = {};
    },

    fecharModalImprimirCertificado() {
      this.modais.exibirImprimirHistorico = false;
      this.modais.historicoDadosAluno = {};
    },

    abrirModalImprimirBoletimENotasParciais(item) {
      this.modais.exibirImprimirBoletim = true;
      this.modais.dadosBoletim = item;
    },

    fecharModalImprimirBoletimENotasParciais() {
      this.modais.exibirImprimirBoletim = false;
      this.modais.dadosBoletim = {};
    },
  },
};
</script>
<style>
.tamanho .table-responsive {
  min-height: 310px;
  margin: 0;
}
</style>
