<template>
  <div class="w-100">
    <div class="row mb-3">
      <div class="col-12 col-md-3">
        <input-text
          ref="filtroMatricula"
          v-model="filtro.nomeAlunoCodigoMatricula"
          :label="$t('ACADEMICO_MATRICULAS.FILTRO_LABEL')"
          :placeholder="$t('ACADEMICO_MATRICULAS.FILTRO_PLACEHOLDER')"
          @enter="buscar"
        />
      </div>

      <div class="col-12 col-md-3">
        <input-select-search
          ref="statusMatricula"
          :label="$t('ACADEMICO.STATUS_MATRICULA')"
          v-model="filtro.statusMatricula"
          @setChanged="buscar"
          :options="opcoes.statusMatricula"
        />
      </div>
      <div class="col-12 col-md-3 botao-acao-filtro">
        <b-button variant="primary" class="mr-2" @click="buscar">
          {{ $t('GERAL.BUSCAR') }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
//Service
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
// Components:
import { InputSelectSearch, InputText } from '@/components/inputs/index';

export default {
  components: {
    // InputBtn,
    InputSelectSearch,
    InputText,
  },
  data() {
    return {
      filtro: {
        filtro: {
          nomeAlunoCodigoMatricula: null,
          statusMatricula: null,
        },
      },
      opcoes: {
        statusMatricula: [],
      },
    };
  },
  mounted() {
    this.getStatusMatricula();
  },
  methods: {
    buscar() {
      this.$emit('filtrar', this.filtro);
    },
    limparFiltro() {
      this.filtro.nomeAlunoCodigoMatricula = null;
      this.filtro.statusMatricula = null;
      this.$emit(
        'limpar-filtro',
        (this.filtro = { nomeAlunoCodigoMatricula: '', statusMatricula: '' })
      );
    },
    getStatusMatricula() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-matricula')
        .then(({ data }) => {
          data = data.map((row) => ({
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.statusMatricula = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
