import ApiService from '@/common/api/api.service';

let apiBasePath = 'HistoricoEscolar';
const HistoricoEscolarService = {
  async imprimir(params) {
    let { data } = await ApiService.post(
      `${apiBasePath}/imprimir-historico`,
      null,
      {
        params: { ...params },
      }
    );
    return data;
  },
};

export default HistoricoEscolarService;
