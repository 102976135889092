var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"empty-text":_vm.$t('ACADEMICO_MATRICULAS.NENHUM_MATRICULA_REGISTRADA'),"head-variant":"light","items":_vm.items,"fields":_vm.fields,"show-empty":"","stacked":"md","hover":""},scopedSlots:_vm._u([{key:"cell(acoes)",fn:function(item){return [(_vm.mostrarAcoes)?_c('div',{staticClass:"flex"},[_c('b-dropdown',{staticClass:"tamanho",attrs:{"variant":"outline-default"}},[(_vm.podeEditar && item.item.status == 'Ativo')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.editar(item.item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('GERAL.EDITAR'))+" ")]):_vm._e(),(_vm.podeExcluir && item.item.status == 'Inativo')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.ativarMatricula(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('GERAL.ATIVAR'))+" ")]):_vm._e(),(_vm.podeExcluir && item.item.status == 'Ativo')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.inativarMatricula(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('GERAL.INATIVAR'))+" ")]):_vm._e(),(item.item.status == 'Ativo')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.visualizar(item.item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('GERAL.VISUALIZAR'))+" ")]):_vm._e(),_c('b-dropdown-item',{on:{"click":function($event){_vm.abrirModalPdfs({
                ...item.item,
                documentoCodigo: 3,
                nomeRelatorio: _vm.$t(
                  'ACADEMICO_MATRICULAS.IMPRIMIR_ATESTADO_FREQUENCIA'
                ),
              })}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO_MATRICULAS.IMPRIMIR_ATESTADO_FREQUENCIA'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.abrirModalImprimirBoletimENotasParciais(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO_MATRICULAS.IMPRIMIR_BOLETIM_NOTAS_PARCIAIS'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.abrirModalImprimirHistorico(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO_MATRICULAS.IMPRIMIR_HISTORICO_ESCOLAR'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.abrirModalImprimirCertificado(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO_MATRICULAS.IMPRIMIR_CERTIFICADO'))+" ")])],1)],1):_vm._e()]}}])}),_c('modal-imprimir-relatorios',{attrs:{"exibir":_vm.modais.exibirBaixarPdfs,"item":_vm.modais.baixarPdf},on:{"fechar":_vm.fecharModalPfs}}),_c('modal-imprimir-historico',{attrs:{"exibir":_vm.modais.exibirImprimirHistorico,"item":_vm.modais.historicoDadosAluno},on:{"fechar":_vm.fecharModalImprimirHistorico}}),_c('modal-imprimir-certificado',{attrs:{"exibir":_vm.modais.exibirImprimirCertificado,"item":_vm.modais.certificadoDadosAluno},on:{"fechar":_vm.fecharModalImprimirCertificado}}),_c('modal-imprimir-boletim',{attrs:{"exibir":_vm.modais.exibirImprimirBoletim,"item":_vm.modais.dadosBoletim},on:{"fechar":_vm.fecharModalImprimirBoletimENotasParciais}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }