import ApiService from '@/common/api/api.service'

let apiBasePath = 'PainelCoordenacao'

const PainelCoordenacaoService = {
    // FUNÇÕES RELATÓRIO
    async imprimir(alunoDados, documento) {
        let { data } = await ApiService.get(`${apiBasePath}/imprimir`,
            {
                params: {
                    MatriculaId: alunoDados.matriculaId,
                    DocumentoCodigo: documento,
                    ImprimeCabecalho: true,
                    SepararFolhas: true,
                    AnoLetivoId: alunoDados.anoLetivoId,
                    CursoId: alunoDados.cursoId,
                    TurmaId: alunoDados.turmaId,
                    GrupoTurmaId: alunoDados.grupoTurmaId,
                }
            }
        )
        return data
    },
    // FUNÇÕES FILTRO
    async obterPorFiltro(filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/listar-por-filtro`,
            {
                params: {
                    AnoLetivoId: filtro.anoLetivoId,
                    CursoId: filtro.cursoId,
                    TurmaId: filtro.turmaId,
                    GrupoTurmaId: filtro.grupoTurmaId
                }
            }
        )
        return data
    },
    // Ocorrencia
    async criarOcorrencia(form) {
        const data = await ApiService.post(`RegistroOcorrencia`, form)
        return data
    },
    async atualizarOcorrencia(form) {
        const data = await ApiService.put(`RegistroOcorrencia`, form)
        return data
    },
    async obterPorFiltroRegistroOcorrencia(filtro) {
        let { data } = await ApiService.get(`RegistroOcorrencia/obter-por-filtro`,
            {
                params: {
                    AnoLetivoId: filtro.anoLetivoId,
                    CursoId: filtro.cursoId,
                    TurmaId: filtro.turmaId,
                    MatriculaId: filtro.matriculaId
                }
            }
        )
        return data
    },
}

export default PainelCoordenacaoService