<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="$t('ACADEMICO_MATRICULAS.IMPRIMIR_BOLETIM_NOTAS_PARCIAIS')"
    :campos="$refs"
    id="modalImprimirBoletim"
    :ocultarRodape="true"
  >
    <input-text
      ref="matricula"
      :label="$t('ACADEMICO.MATRICULA')"
      v-model="item.codigoMatricula"
      disabled
    />
    <input-text
      ref="nomeAluno"
      :label="$t('ACADEMICO.NOME')"
      v-model="item.nome"
      disabled
    />

    <input-select
      ref="cursoTurma"
      :label="$t('ACADEMICO_MATRICULAS.CURSO_TURMA')"
      :options="opcoes.cursoTurma"
      v-model="cursoTurma"
      required
    />
    <div class="d-flex justify-content-between">
      <b-button variant="secondary" @click="fecharModal()">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
      <b-button variant="primary" @click="imprimir()">
        {{ $t('ACADEMICO_MATRICULAS.GERAR_BOLETIM') }}
      </b-button>
    </div>
  </modal>
</template>

<script>
//aux & helpers
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';

// services
import BoletimService from '@/common/services/boletim/boletim.service.js';
import ContratoService from '@/common/services/contratos/contratos.service.js';

// components
import Modal from '@/components/modal/Modal.vue';

import { InputSelect, InputText } from '@/components/inputs';

export default {
  components: {
    Modal,
    InputSelect,
    InputText,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: null },
  },
  data() {
    return {
      cursoTurma: {},
      opcoes: {
        cursoTurma: [],
      },
    };
  },
  watch: {
    exibir: 'pegarCursoTurma',
  },
  methods: {
    pegarCursoTurma(exibir) {
      if (exibir) this.$store.dispatch(START_LOADING);
      ContratoService.listarCursoTurmaVigentesPorMatricula(this.item.id)
        .then(({ data }) => {
          data = data.map((row) => ({
            text: `Ano - ${row.anoLetivoDescricao} - ${row.cursoDescricao} - ${row.turmaDescricao} - Data Validade: ${row.validade}`,
            value: row,
          }));
          this.opcoes.cursoTurma = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    imprimir() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      BoletimService.imprimirBoletimNotas({
        anoLetivoId: this.cursoTurma.anoLetivoId,
        matriculaId: this.cursoTurma.matriculaId,
        turmaId: this.cursoTurma.turmaId,
      })
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
