<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="$t('ACADEMICO_MATRICULAS.IMPRIMIR_CERTIFICADO')"
    :campos="$refs"
    id="modalImprimirCertificado"
    :ocultarRodape="true"
  >
    <!-- validarPreenchimento -->
    <input-select
      ref="tipoHistorico"
      :label="$t('ACADEMICO.TIPO_CERTIFICADO')"
      :options="opcoes.tipoHistorico"
      v-model="tipoHistorico"
      required
    />

    <div class="d-flex justify-content-between">
      <b-button variant="secondary" @click="fecharModal()">
        {{ $t('GERAL.FECHAR') }}
      </b-button>

      <b-button variant="primary" @click="imprimir()">
        {{ $t('GERAL.IMPRIMIR') }}
      </b-button>
    </div>
  </modal>
</template>
  
  <script>
//aux & helpers
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';

// services
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import HistoricoEscolarService from '@/common/services/academico/historicoEscolar.service';
// components
import Modal from '@/components/modal/Modal.vue';

import { InputSelect } from '@/components/inputs';

export default {
  components: {
    Modal,
    InputSelect,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: null },
  },
  data() {
    return {
      tipoHistorico: '',
      opcoes: {
        tipoHistorico: [],
      },
    };
  },
  mounted() {
    this.pegarEnumTipoHitorico();
  },
  methods: {
    // pegar dados
    pegarEnumTipoHitorico() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('fase')
        .then(({ data }) => {
          data = data.map((row) => ({
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoHistorico = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    imprimir() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      HistoricoEscolarService.imprimir({
        tipoHistorico: this.tipoHistorico,
        matriculaId: this.item.id,
        layoutHistoricoEscolar: 2,
      })
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>